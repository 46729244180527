import React from 'react';
import { Typography, Grid } from "@mui/material";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'; // Импорт иконки MUI
import { styled } from "@mui/system";

// Стилизованная кнопка выбора файла
const StyledLabel = styled('label')(({ themeParams }) => ({
    padding: '8px 16px',
    backgroundColor: themeParams ? themeParams.button_color : '#3A205B',
    color: '#fff',
    borderRadius: '50px',
    cursor: 'pointer',
    display: 'flex', // Оставляем только display: 'flex'
    alignItems: 'center',
    '&:hover': {
        backgroundColor: themeParams ? themeParams.button_hover_color : '#311a4f',
    },
}));

const FileUploadButton = ({ onFileChange, selectedFileName, title, buttonText, className, style, themeParams }) => {
    // Обработчик изменения файла
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Получаем первый файл
        if (file) {
            onFileChange(file); // Передаем файл в родительский компонент
        }
    };

    return (
        <div className={className} style={style}>
            {/* Скрытый input для загрузки файлов */}
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }} // Скрываем input через стили
                onChange={handleFileChange} // Вызываем локальный обработчик
            />

            {/* Структура для кнопки загрузки файла */}
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                        {title || "Upload File"}:
                    </Typography>
                </Grid>
                <Grid item container spacing={2} alignItems="center">
                    <Grid item>
                        <StyledLabel
                            htmlFor="fileInput"
                            themeParams={themeParams}
                        >
                            <PhotoCameraIcon className="mr-2" style={{ width: '16px', height: '16px' }} />
                            {buttonText || 'Choose File'}
                        </StyledLabel>
                    </Grid>
                    <Grid item>
                        <Typography
                            className="text-12 font-medium ml-2"
                            color="text.secondary"
                        >
                            {selectedFileName || "No file selected"}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default FileUploadButton;