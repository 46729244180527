import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

// Создаем стилизованный контейнер для селекта
const StyledSelectContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

// Создаем стилизованный Label для селекта
const StyledLabel = styled('label')(({ themeParams }) => ({
    fontSize: '12px',
    color: themeParams.section_header_text_color,
    marginBottom: '5px',
    textTransform: 'uppercase',
}));

// Создаем стилизованный Select
const StyledSelect = styled(Select)(({ themeParams }) => ({
    width: '100%',
    fontSize: '16px',
    color: themeParams.text_color,
    borderRadius: '12px',
    marginBottom: '10px',
    border: `1px solid ${themeParams.section_header_text_color}`,
    backgroundColor: themeParams.secondary_bg_color,
    outline: 'none',
    '& .MuiSelect-icon': {
        color: themeParams.text_color, // Стиль иконки выпадающего списка
    },
    '&:focus': {
        border: `2px solid ${themeParams.button_color}`,
    },
}));

// Function to get gradient details based on the template
const getGradient = (template) => {
    const backgrounds = {
        t1: { backgroundColor: '#0093E9', backgroundImage: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)' },
        t2: { backgroundColor: '#4158D0', backgroundImage: 'linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)' },
        t3: { backgroundColor: '#8EC5FC', backgroundImage: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)' },
        t4: { backgroundColor: '#F3904F', backgroundImage: 'linear-gradient(135deg, #F3904F 0%, #3B4371 100%)' },
        t5: { backgroundColor: '#85FFBD', backgroundImage: 'linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%)' },
        t6: { backgroundColor: '#FF5F6D', backgroundImage: 'linear-gradient(to right, #FF5F6D, #FFC371)' },
        t7: { backgroundColor: '#00C9FF', backgroundImage: 'linear-gradient(to right, #00C9FF, #92FE9D)' },
        t8: { backgroundColor: '#FC466B', backgroundImage: 'linear-gradient(to right, #FC466B, #3F5EFB)' },
        t9: { backgroundColor: '#ff758c', backgroundImage: 'linear-gradient(to right, #ff758c, #ff7eb3)' },
        t10: { backgroundColor: '#2b5876', backgroundImage: 'linear-gradient(to right, #2b5876, #4e4376)' },
        t11: { backgroundColor: '#ee9ca7', backgroundImage: 'linear-gradient(to right, #ee9ca7, #ffdde1)' },
        t12: { backgroundColor: '#42275a', backgroundImage: 'linear-gradient(to right, #42275a, #734b6d)' },
        t13: { backgroundColor: '#00b09b', backgroundImage: 'linear-gradient(to right, #00b09b, #96c93d)' },
        t14: { backgroundColor: '#FF512F', backgroundImage: 'linear-gradient(to right, #FF512F, #F09819)' },
        t15: { backgroundColor: '#56CCF2', backgroundImage: 'linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%)' }
    };

    return backgrounds[template] || null;
};

// Presets for the selector
const presets = [
    { name: "Modern", value: "modern", details: { fontSize: '36px', fontColor: '#ffffff', fontFamily: 'Lobster', backgroundGradientTemplate: 't1', borderColor: '#0093E9', borderWidth: '0px', shadow: 'on' }},
    { name: "Classic", value: "classic", details: { fontSize: '32px', fontColor: '#fff', fontFamily: 'Playfair Display', backgroundGradientTemplate: 't10', borderColor: '#2b5876', borderWidth: '0px', shadow: 'off' }},
    { name: "Minimal", value: "minimal", details: { fontSize: '28px', fontColor: '#000000', fontFamily: 'PT Sans', bgColor: '#ffffff', borderColor: '#000000', borderWidth: '0px', shadow: 'off' }},
    { name: "Vibrant", value: "vibrant", details: { fontSize: '34px', fontColor: '#ffe1c7', fontFamily: 'Raleway', backgroundGradientTemplate: 't14', borderColor: '#FF512F', borderWidth: '0px', shadow: 'on' }},
    { name: "Elegant", value: "elegant", details: { fontSize: '38px', fontColor: '#ffffff', fontFamily: 'Montserrat', backgroundGradientTemplate: 't2', borderColor: '#C850C0', borderWidth: '0px', shadow: 'off' }},
    { name: "Bold", value: "bold", details: { fontSize: '40px', fontColor: '#ffffff', fontFamily: 'Montserrat', backgroundGradientTemplate: 't3', borderColor: '#8EC5FC', borderWidth: '0px', shadow: 'off' }},
    { name: "Fresh", value: "fresh", details: { fontSize: '38px', fontColor: '#2b5876', fontFamily: 'Montserrat', backgroundGradientTemplate: 't9', borderColor: '#ff758c', borderWidth: '0px', shadow: 'off' }},
    { name: "Retro", value: "retro", details: { fontSize: '35px', fontColor: '#ffffff', fontFamily: 'Exo 2', backgroundGradientTemplate: 't8', borderColor: '#FC466B', borderWidth: '0px', shadow: 'on' }},
    { name: "Calm", value: "calm", details: { fontSize: '37px', fontColor: '#ffffff', fontFamily: 'Amatic SC', backgroundGradientTemplate: 't10', borderColor: '#F3904F', borderWidth: '0px', shadow: 'off' }},
    { name: "Futuristic", value: "futuristic", details: { fontSize: '38px', fontColor: '#ffffff', fontFamily: 'Roboto', backgroundGradientTemplate: 't7', borderColor: '#00C9FF', borderWidth: '0px', shadow: 'on' }},
    { name: "Dark Mode", value: "darkMode", details: { fontSize: '34px', fontColor: '#ffffff', fontFamily: 'Fira Sans', backgroundGradientTemplate: 't12', borderColor: '#42275a', borderWidth: '0px', shadow: 'on' }},
    { name: "Nature", value: "nature", details: { fontSize: '37px', fontColor: '#ffffff', fontFamily: 'PT Sans', backgroundGradientTemplate: 't13', borderColor: '#00b09b', borderWidth: '0px', shadow: 'off' }},
    { name: "Sunny", value: "sunny", details: { fontSize: '37px', fontColor: '#ffffff', fontFamily: 'Abril Fatface', backgroundGradientTemplate: 't5', borderColor: '#FFFB7D', borderWidth: '0px', shadow: 'on' }},
    { name: "Pastel", value: "pastel", details: { fontSize: '30px', fontColor: '#ffffff', fontFamily: 'Raleway', backgroundGradientTemplate: 't11', borderColor: '#ffdde1', borderWidth: '0px', shadow: 'off' }},
    { name: "Artistic", value: "artistic", details: { fontSize: '39px', fontColor: '#ffffff', fontFamily: 'Lobster', backgroundGradientTemplate: 't6', borderColor: '#FF5F6D', borderWidth: '0px', shadow: 'on' }},
];

function PresetSelector({ label, value, onChange, className, style, itemClassName, themeParams }) {
    return (
        <StyledSelectContainer>
            <StyledLabel themeParams={themeParams}>{label}</StyledLabel>
            <StyledSelect
                labelId="preset-selector-label"
                id="preset-selector"
                value={value}
                onChange={onChange}
                label={label}
                themeParams={themeParams}
                MenuProps={{ disableScrollLock: true }} // Prevent focus trapping issues
            >
                {presets.map((preset, index) => {
                    const gradient = getGradient(preset.details.backgroundGradientTemplate);
                    return (
                        <MenuItem key={index} value={preset.value} className={itemClassName}>
                            <span style={{
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                marginRight: '10px',
                                border: '1px solid #000',
                                backgroundColor: gradient ? gradient.backgroundColor : preset.details.fontColor,
                                backgroundImage: gradient ? gradient.backgroundImage : 'none'
                            }} />
                            {preset.name}
                        </MenuItem>
                    );
                })}
            </StyledSelect>
        </StyledSelectContainer>
    );
}

export default PresetSelector;