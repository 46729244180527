import React, {useState, useEffect, createContext} from "react";
import {useLocation} from 'react-router-dom';
import {styled} from '@mui/system';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/MenuBook';
import AssessmentIcon from '@mui/icons-material/Assessment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import useWebApp from './twa/useWebApp';
import Courses from './course/Main';
import './App.css';
import {useSelector, useDispatch} from 'react-redux';
import {setSelectedAssignment, setTab} from "./store";
import '@telegram-apps/telegram-ui/dist/styles.css';
import { Route, Routes } from 'react-router-dom';
import CourseDetails from './course/CourseDetails';
import { setAccessToken } from "./store";

axios.defaults.baseURL = "https://api.coob.app";
axios.defaults.withCredentials = true;

export default function MyApp() {
    const location = useLocation();

    const correctedSearch = location.search.replace(/&amp;/g, '&');
    const queryParams = new URLSearchParams(correctedSearch);

    const tab = queryParams.get('tab') || "main";

    const [loading, setLoading] = useState(true);
    const [activeTime, setActiveTime] = useState(null);

    const dispatch = useDispatch();
    const value = useSelector(state => state.tab);

    let WebApp = useWebApp();

    const [dark, setDark] = useState(false);
    const [user, setUser] = useState(null);
    const themeParams = WebApp.themeParams || {};

    const [language, setLanguage] = useState('en'); // Add language state

    useEffect(() => {
        if (WebApp) {
            WebApp.setHeaderColor('secondary_bg_color');
        }
    }, [WebApp]);

    useEffect(() => {
        if (WebApp) {
            WebApp.ready();
            setLoading(true);

            const tabMapping = {
                'assignments': 1,
                'notifications': 2,
                'profile': 3,
                'main': 0
            };

            const initialTab = tabMapping[tab] !== undefined ? tabMapping[tab] : 0;
            dispatch(setTab(initialTab));

            const colorScheme = WebApp.colorScheme || 'light';
            setDark(colorScheme === 'dark');

            axios.post("/v1/auth/login/telegram-app", {
                web_app_data: WebApp.initData,
            }, {
                withCredentials: true,
            }).then(response => {
                const token = response.data.data.access_token;
                dispatch(setAccessToken(token)); // Используйте dispatch для установки токена
                setUser(response.data.data.user);
                setLanguage(response.data.data.user.language || 'en');
                setLoading(false);

                if (!WebApp.isExpanded) {
                    WebApp.expand();
                }
            }).catch(error => {
                WebApp = null;
                console.error("Error:", error);
                setLoading(false);
            });
        }
    }, [WebApp, tab, dispatch]);

    const StyledContainer = styled('div')({
        backgroundColor: themeParams.secondary_bg_color,
        color: themeParams.text_color,
        backgroundImage: 'none',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '70px', // Add bottom padding to prevent content overlay
    });

    const StyledBottomNavigation = styled(BottomNavigation)(({theme}) => ({
        backgroundColor: `${themeParams.secondary_bg_color} !important`,
        color: themeParams.text_color,
        position: 'fixed',
        bottom: 0,
        height: '70px',
        paddingBottom: '16px',
        width: '100%',
        boxShadow: theme.shadows[4],
        zIndex: 1000, // Set high z-index for navigation
        '& .Mui-selected': {
            color: `${themeParams.button_color} !important`,
        },
        '& .MuiBottomNavigationAction-label': {
            color: themeParams.bg_color,
        },
    }));

    // Function to convert hex to rgb
    const hexToRgb = (hex) => {
        let bigint = parseInt(hex.substring(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    };

    const rgbToCss = (rgb) => `rgb(${rgb.join(', ')}, 0.3)`;

    if (!WebApp) {
        return null;
    }

    const theme = createTheme({
        palette: {
            mode: dark ? 'dark' : 'light',
        },
    });

    const renderPage = () => {
        switch (value) {
            case 0:
                return (
                    <Routes>
                        <Route path="/" element={<Courses themeParams={themeParams} language={language} />} />
                        <Route path="/courses/:courseId" element={<CourseDetails themeParams={themeParams} language={language} />} /> {/* Add route for course details */}
                    </Routes>
                );
            default:
                return null;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <StyledContainer>
                <div style={{flex: 1}}>
                    {renderPage()}
                </div>

                    <StyledBottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setActiveTime(new Date());
                            dispatch(setTab(newValue));
                            dispatch(setSelectedAssignment(null));
                            window.scrollTo(0, 0);
                        }}
                    >
                        <BottomNavigationAction icon={<HomeIcon sx={{fontSize: 28}}/>}/>
                        <BottomNavigationAction icon={<AssessmentIcon sx={{fontSize: 28}}/>}/>
                        <BottomNavigationAction icon={<NotificationsIcon sx={{fontSize: 28}}/>}/>
                        {user ? (
                            <BottomNavigationAction
                                icon={
                                    <Avatar
                                        alt={user.full_name}
                                        src={user.avatar}
                                        sx={{width: 28, height: 28, margin: '0 auto 0px'}}
                                    />
                                }
                            />
                        ) : (
                            <BottomNavigationAction icon={<PersonIcon sx={{fontSize: 28}}/>}/>
                        )}
                    </StyledBottomNavigation>

            </StyledContainer>
        </ThemeProvider>
    );
}