import React from 'react';
import { styled } from '@mui/system';

// Создаем компонент StyledInput с использованием MUI styled и themeParams
const StyledInputContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const StyledLabel = styled('label')(({ themeParams }) => ({
    fontSize: '12px',
    color: themeParams.section_header_text_color, // Используем цвет из themeParams
    marginBottom: '5px',
    textTransform: 'uppercase', // Делаем текст заголовка большими буквами
}));

const StyledInput = styled('input')(({ themeParams }) => ({
    width: '100%',
    padding: '10px 15px',
    fontSize: '16px',
    color: themeParams.text_color,
    borderRadius: '12px',
    marginBottom: '20px',
    border: `1px solid ${themeParams.section_header_text_color}`,
    outline: 'none',
    backgroundColor: themeParams.secondary_bg_color,
    transition: 'border-color 0.3s',
    '&:focus': {
        border: `2px solid ${themeParams.button_color}`,
        backgroundColor: themeParams.secondary_bg_color,
    },
}));

// Компонент CustomInput с параметрами label, value, onChange, onFocus и themeParams
const CustomInput = ({ ref, label, value, onChange, onFocus, onBlur, themeParams }) => {
    return (
        <StyledInputContainer>
            <StyledLabel themeParams={themeParams}>{label}</StyledLabel>
            <StyledInput
                ref={ref}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus} // Добавляем onFocus для обработки события фокуса
                themeParams={themeParams} // Передаем themeParams для использования цвета
            />
        </StyledInputContainer>
    );
};

export default CustomInput;