import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button as MUIButton, Card, Typography, CircularProgress, IconButton, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import { formatDistance } from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import SkeletonCourse from './SkeletonCourse';
import CoverGenerator from './CoverGenerator';
import { translate } from '../translations';
import './Courses.css';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

import { Button, Section, Placeholder } from '@telegram-apps/telegram-ui';
import Header from "../Header";
import useWebApp from '../twa/useWebApp';

const Courses = ({ themeParams, language }) => {
    const navigate = useNavigate(); // Hook to handle navigation
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.accessToken);
    let WebApp = useWebApp();
    const colorScheme = WebApp.colorScheme || 'light';

    // Add click handler to navigate to the course details
    const handleCardClick = (courseId) => {
        navigate(`/courses/${courseId}`);
    };

    // Styled components
    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '20px',
        margin: '20px 30px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        position: 'relative', // To position the delete button
    });

    const ImageWrapper = styled('div')({
        width: '100%',
        height: '200px',
        overflow: 'hidden',
    });

    const StyledImage = styled('img')({
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    });

    const ContentWrapper = styled('div')({
        padding: '16px',
    });

    const TitleTypography = styled(Typography)({
        marginTop: '8px',
        fontSize: '1.1rem',
        fontWeight: '500',
        color: themeParams.text_color,
        textAlign: 'left',
    });

    const CustomDialog = styled(Dialog)({
        '& .MuiDialog-paper': {
            backgroundColor: `${themeParams.section_bg_color} !important`, // Применение !important к цвету фона
            backgroundImage: 'none !important', // Удаление фонового изображения
            color: '${themeParams.text_color} !important', // Применение !important к цвету текста
            borderRadius: '20px', // Закругление углов
            padding: '5px', // Добавление отступов для более приятного внешнего вида
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Легкая тень для объема
        },
    });

    const DescriptionTypography = styled(Typography)({
        marginTop: '8px',
        color: themeParams.text_secondary_color,
        fontSize: '0.875rem',
        textAlign: 'left',
        lineClamp: 2,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    });

    useEffect(() => {
        const controller = new AbortController(); // Создаем контроллер для управления запросом
        let isMounted = true; // Флаг монтирования компонента

        const fetchCourses = async () => {
            if (isFetching) return; // Проверяем, идет ли запрос

            setIsFetching(true);
            try {
                const response = await axios.get(`/v1/courses?size=100&page=1`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                    signal: controller.signal, // Передаем сигнал для контроля отмены
                });

                if (isMounted) { // Проверяем, что компонент все еще монтирован
                    const newCourses = response.data.data || [];
                    setCourses(prevCourses => {
                        const allCourses = [...prevCourses, ...newCourses];
                        const uniqueCourses = Array.from(new Set(allCourses.map(course => course.id)))
                            .map(id => allCourses.find(course => course.id === id));
                        return uniqueCourses;
                    });
                    setLoading(false);
                }
            } catch (error) {
                if (error.name !== 'CanceledError') {
                    console.error("Ошибка при загрузке курсов:", error);
                }
            } finally {
                if (isMounted) setIsFetching(false); // Сбрасываем флаг, если компонент монтирован
            }
        };

        fetchCourses();

        return () => {
            isMounted = false; // Обновляем флаг монтирования
            controller.abort(); // Отменяем запрос при размонтировании
        };
    }, [accessToken]);

    const createCourseClick = () => {
        createNewCourse();
    }

    const createNewCourse = async () => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const response = await axios.post('/v1/courses', {
                title: "Untitled",
                description: "default course description"
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (response.data.code === "success") {
                navigate(`/courses/${response.data.data}`);
            } else {
                // Обработка других ошибок API
                console.error("Ошибка при создании курса:", response.data);
            }
        } catch (error) {
            if (error.response.data.type === 'plan_limit_exceeded') {
                //setOpen(true);
            }

            // Обработка ошибок сети
            console.error("Ошибка при создании курса:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteClick = (course) => {
        WebApp.showConfirm(translate(language, 'delete_course_warning'), async (agree) => {
            if (agree) {
                try {
                    await axios.delete(`/v1/courses/${course.id}`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    setCourses(courses.filter(c => c.id !== course.id));
                } catch (error) {
                    console.error("Ошибка при удалении курса:", error);
                }
            }
        });
    };

    return (
        <div>
            <Header dark={colorScheme === 'dark'} themeParams={themeParams} />
            <Section
                className="section-add"
                header={translate(language, 'create_course_header')}
                footer={translate(language, 'courses')}
            >
                <div className="section-button-container">
                    <Button
                        onClick={createCourseClick}
                        className="section-button"
                        mode="bezeled"
                        before={<AddIcon />}
                        loading={isLoading}
                    >
                        {translate(language, 'create_course')}
                    </Button>
                </div>
            </Section>

            {loading ? (
                <>
                    <SkeletonCourse themeParams={themeParams} />
                    <SkeletonCourse themeParams={themeParams} />
                </>
            ) : (
                courses.map(course => (
                    <StyledCard key={course.id} onClick={() => handleCardClick(course.id)}>
                        <ImageWrapper>
                            {course.image_url ? (
                                <StyledImage src={course.image_url} alt={course.title} />
                            ) : (
                                course.settings?.course_cover_preset && (
                                    <CoverGenerator width={300} preset={course.settings.course_cover_preset}
                                                    text={course.title} />
                                )
                            )}
                        </ImageWrapper>
                        <ContentWrapper>
                            <TitleTypography>{course.title}</TitleTypography>
                            <Divider sx={{ my: 1, backgroundColor: themeParams.section_separator_color }} />
                            <DescriptionTypography>{course.description}</DescriptionTypography>
                            <div className="flex justify-between items-center mt-4">
                                <div style={{ flexGrow: 1 }}></div>
                                <Typography sx={{ color: themeParams.section_header_text_color, textAlign: 'right' }}
                                            className="text-sm">
                                    {formatDistance(new Date(course.created_at), new Date(), { addSuffix: true })}
                                </Typography>
                            </div>
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    backgroundColor: `${themeParams.button_color}AA`, // Прозрачность фона
                                    backgroundImage: 'none',
                                    backdropFilter: 'blur(5px)', // Эффект размытия
                                    '&:hover': {
                                        backgroundColor: `${themeParams.button_color}CC`, // Прозрачность при наведении
                                    },
                                }}
                                onClick={(event) => {
                                    event.stopPropagation(); // Prevents the card click event from being triggered
                                    handleDeleteClick(course);
                                }}
                                aria-label="delete"
                            >
                                <DeleteIcon sx={{ color: themeParams.button_text_color }} />
                            </IconButton>
                        </ContentWrapper>
                    </StyledCard>
                ))
            )}
            {isFetching && (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                    <CircularProgress />
                </div>
            )}
            {!hasMore && !loading && courses.length === 0 && (
                <Placeholder description={translate(language, 'no_courses')}>
                </Placeholder>
            )}
        </div>
    );
};

export default Courses;