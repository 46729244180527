import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import { Typography, Box, Stepper, Step, StepLabel, StepContent, Chip } from '@mui/material';
import useWebApp from "../twa/useWebApp";
import CourseCard from './CourseCard';
import SkeletonCourse from "./SkeletonCourse";
import { styled } from '@mui/system';
import CourseEditModal from './CourseEditModal'; // Импортируем новый компонент модального окна
import {setAccessToken, setSelectedCourse, setSelectedStep} from "../store";
import { StepConnector } from '@mui/material';
import NewStepItem from './NewStepItem';

// Define a custom connector with dashed and thicker lines
const CustomConnector = styled(StepConnector)(({ themeParams }) => ({
}));

// Update your CourseDetails component
const CourseDetails = ({ themeParams, language }) => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const [loading, setLoading] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const WebApp = useWebApp();
    const accessToken = useSelector(state => state.accessToken);
    const course = useSelector(state => state.selectedCourse);
    const dispatch = useDispatch();
    const selectedStep = useSelector(state => state.selectedStep) || {id: courseId, type: 'course'};

    const handleStepClick = (id, type) => () => {
        dispatch(setSelectedStep({ id, type }));
    };

    const handleBackClick = useCallback(() => {
        navigate(`/`);
        if (WebApp) WebApp.BackButton.hide();
    }, [WebApp]);

    const CBBox = styled(Box)({
        padding: '20px',
    });

    const CBStepLabel = styled(StepLabel)({
        color: themeParams.section_header_text_color,
        fontSize: '0.80rem',
        textAlign: 'left',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
    });

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setSelectedStep({ id: courseId, type: 'course' }));

        if (WebApp) WebApp.BackButton.show();
        if (WebApp) {
            WebApp.BackButton.onClick(handleBackClick);
        }
    }, [WebApp, handleBackClick]);

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await axios.get(`/v1/courses/${courseId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                dispatch(setSelectedCourse(response.data.data));
            } catch (error) {
                console.error('Error fetching course details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourse();
    }, [courseId, accessToken]);

    const handleCardClick = () => {
        setIsEditModalOpen(true);
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
    };

    const handleEditModalOpenChange = (open) => {
        setIsEditModalOpen(open);
    };

    if (loading) return <SkeletonCourse themeParams={themeParams} />;
    if (!course) return <Typography>No course details available</Typography>;

    return (
        <div>
            <CBBox>
                <Stepper
                    orientation="vertical"
                    connector={<CustomConnector themeParams={themeParams} />} // Use custom connector
                >
                    <Step key={course.id} active={course.id === selectedStep.id && selectedStep.type === "course"}  completed={course.id === selectedStep.id && selectedStep.type === "course"} >
                        <CBStepLabel style={{ cursor: 'pointer' }} onClick={handleStepClick(course.id, "course")}>
                            {course.title}
                        </CBStepLabel>
                        <StepContent>
                            <div >
                                <CourseCard themeParams={themeParams} handleCardClick={handleCardClick} />
                            </div>
                        </StepContent>
                    </Step>

                    {/* Steps */}
                    {course.steps?.map((step, index) => (
                        <Step key={step.id} active={step.id === selectedStep.id && selectedStep.type === "step"}  completed={step.id === selectedStep.id && selectedStep.type === "step"} >
                            <CBStepLabel style={{ cursor: 'pointer' }} onClick={handleStepClick(step.id, "step")}>
                                {step.name || 'Step'}
                            </CBStepLabel>
                            <StepContent>
                                {step.name || 'Step'}
                                <Box pt={2}>
                                    {step.status === 'public' && (
                                        <Chip
                                            label="Public"
                                            color="success"
                                            size="small"
                                            className="mr-10"
                                        />
                                    )}
                                    {step.status === 'draft' && (
                                        <Chip label="Draft" size="small" className="mr-10" />
                                    )}
                                    <Chip
                                        label={step.type === 'invoice' ? 'Invoice ⭐' : step.type}
                                        size="small"
                                    />
                                </Box>
                            </StepContent>
                        </Step>
                    ))}

                    {/* new step */}
                    <Step>
                        <StepLabel>
                            <NewStepItem themeParams={themeParams} language={language} />
                        </StepLabel>
                    </Step>
                </Stepper>
            </CBBox>

            {/* Edit Modal */}
            <CourseEditModal
                language={language}
                themeParams={themeParams}
                open={isEditModalOpen}
                onClose={handleEditModalClose}
                openChange={handleEditModalOpenChange}
            />
        </div>
    );
};

export default CourseDetails;