const translations = {
    en: {
        completed: "Done!",
        continue: "Keep Going",
        profile: "My Profile",
        assignments: "Tasks",
        payments: "Payments",
        lessons: "Lessons",
        dashboard: "Home",
        progress: "Progress",
        edit_profile: "Update Profile",
        attempts: "Tries",
        comments: "Feedback",
        created: "Created",
        attempt: "Try",
        start: "Start",
        no_notifications: "No notifications to show.",
        no_assignments: "No tasks to show.",
        status: "Status",
        notifications: "Notifications",
        date: "Date",
        no_payments: "No completed payments ⭐",
        current_step_locked: "Complete this step to unlock the next one.",
        step_locked: "Complete the previous steps so that the content is available.",
        reset_progress: "Reset Progress",
        reset_confirm_message: "Are you sure you want to reset your progress? This action cannot be undone.",
        courses: "Recently Created Courses",
        add_course: "Add Course",
        create_course: "Create Course",
        no_courses: "You don't create any courses yet.",
        create_course_header: "Create a Course to Share Your Knowledge",
        delete_course_confirmation: "Delete Course",
        delete_course_warning: "Are you sure you want to delete this course? This action cannot be undone.",
        cancel: "Cancel",
        delete: "Delete",
        save: "Save",
        publish_course: "Publish Course",
        available_bots: "Available Telegram Bots",
        description: "Description",
        title: "Title",
        empty: "None",
        choose_preset: "Choose a Preset",
        choose_image: "Choose Image",
        upload_cover_image: "Upload Cover Image",
        upload: "Upload",
        generate: "Generate",
        edit: "Edit",
    },
    ru: {
        edit: "Редактировать",
        generate: "Сгенерировать",
        upload: "Загрузить",
        upload_cover_image: "Загрузить обложку",
        choose_image: "Выберите изображение",
        choose_preset: "Выберите шаблон",
        empty: "Пусто",
        title: "Название",
        description: "Описание",
        available_bots: "Доступные Телеграм Боты",
        publish_course: "Опубликовать Курс",
        save: "Сохранить",
        create_course_header: "Создайте курс, чтобы поделиться своими знаниями",
        create_course: "Создать Курс",
        add_course: "Добавить Курс",
        no_courses: "Вы еще не создали ни одного курса.",
        courses: "Последние созданные курсы",
        completed: "Готово!",
        reset_progress: "Сбросить Прогресс",
        reset_confirm_message: "Вы уверены, что хотите сбросить свой прогресс? Это действие нельзя отменить.",
        continue: "Продолжить",
        start: "Начать",
        profile: "Мой Профиль",
        notifications: "Уведомления",
        no_notifications: "Нет уведомлений для показа.",
        assignments: "Задания",
        lessons: "Уроки",
        payments: "Платежи",
        dashboard: "Главная",
        progress: "Прогресс",
        edit_profile: "Обновить Профиль",
        attempts: "Попытки",
        comments: "Отзывы",
        created: "Создано",
        attempt: "Попробовать",
        no_assignments: "Нет заданий для показа.",
        status: "Статус",
        date: "Дата",
        no_payments: "Нет совершенных платежей ⭐",
        current_step_locked: "Завершите этот шаг, чтобы разблокировать следующий.",
        step_locked: "Завершите предыдущее шаги, чтобы контент был доступен.",
        delete_course_confirmation: "Удалить Курс",
        delete_course_warning: "Вы уверены, что хотите удалить этот курс? Это действие нельзя отменить.",
        cancel: "Отмена",
        delete: "Удалить",
    }
};

export function translate(languageCode, key) {
    return translations[languageCode]?.[key] || translations.en[key];
}