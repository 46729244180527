import React from 'react';
import { Card, CardContent, Skeleton, Divider } from '@mui/material';
import { styled } from '@mui/system';

const SkeletonCourse = ({ themeParams }) => {
    // Стили для карты курса, аналогичные вашим картам курса
    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '20px',
        margin: '20px 30px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    });

    const ImageWrapper = styled('div')({
        width: '100%',
        height: '200px',
        overflow: 'hidden',
        position: 'relative',
    });

    const ContentWrapper = styled(CardContent)({
        padding: '16px',
    });

    return (
        <StyledCard>
            {/* Скелетон для изображения */}
            <ImageWrapper>
                <Skeleton variant="rectangular" width="100%" height="100%" />
            </ImageWrapper>

            <ContentWrapper>
                {/* Скелетон для заголовка */}
                <Skeleton variant="text" width="80%" height={30} sx={{ marginBottom: 1 }} />

                {/* Скелетон для разделителя */}
                <Divider sx={{ my: 1, backgroundColor: themeParams.section_separator_color }} />

                {/* Скелетон для описания */}
                <Skeleton variant="text" width="90%" height={20} sx={{ marginBottom: 0.5 }} />
                <Skeleton variant="text" width="60%" height={20} sx={{ marginBottom: 1 }} />

                {/* Скелетон для даты */}
                <Skeleton variant="text" width="40%" height={15} sx={{ alignSelf: 'flex-end', textAlign: 'right' }} />
            </ContentWrapper>
        </StyledCard>
    );
};

export default SkeletonCourse;