import React, { useState } from 'react';
import axios from 'axios';
import { Box, styled, Tooltip, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStep, setSelectedCourse } from "../store";

// Styled components for the icons
const IconContainer = styled('div')(({ themeParams }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    padding: '16px',
    cursor: 'pointer',
    border: `1px solid ${themeParams.button_color}`,
}));

// No hover container for the static add icon
const NoHoverContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderRadius: '20px',
    padding: '16px',
    cursor: 'default', // Make sure this doesn't have a pointer cursor
});

function NewStepItem({ themeParams, language }) {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStepType, setLoadingStepType] = useState(null); // Store the type of loading step
    const course = useSelector(state => state.selectedCourse);
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.accessToken);

    async function createStep(stepType) {
        if (isLoading) return; // Prevent multiple submissions
        setIsLoading(true);
        setLoadingStepType(stepType); // Set the current step type for loading

        try {
            const stepData = {
                course_id: course.id,
                text: '',
                payload: null,
                type: stepType,
                is_collection: stepType === 'collection',
                name: '',
                step_number: course.steps ? course.steps.length + 1 : 0,
            };

            const response = await axios.post('v1/steps', stepData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            const newStep = response.data.data;
            const updatedCourse = {
                ...course,
                steps: course.steps ? [...course.steps, newStep] : [newStep]
            };

            dispatch(setSelectedCourse(updatedCourse));
            dispatch(setSelectedStep({
                id: newStep.id,
                type: "step",
                entity: newStep
            }));

        } catch (error) {
            console.error("Error creating step:", error);
        } finally {
            setIsLoading(false);
            setLoadingStepType(null); // Reset the loading step type
        }
    }

    return (
        <Box
            sx={{
                borderColor: 'divider',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                borderRadius: '20px',
                backgroundColor: themeParams.section_bg_color,
                transition: 'background-color 0.3s ease-in-out',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            }}
            role="button"
            tabIndex={0}
        >
            <Box sx={{ display: 'flex', paddingLeft: 2, gap: 4 }}>

                {/* Icon for adding text */}
                <Tooltip title="Add Text Step">
                    <IconContainer themeParams={themeParams} onClick={() => createStep('text')}>
                        {isLoading && loadingStepType === 'text' ? (
                            <CircularProgress size={24} color="inherit" sx={{ color: themeParams.button_color, }} />
                        ) : (
                            <DescriptionIcon sx={{ color: themeParams.button_color }} />
                        )}
                    </IconContainer>
                </Tooltip>

                {/* Icon for adding image */}
                <Tooltip title="Add Image Step">
                    <IconContainer themeParams={themeParams} onClick={() => createStep('image')}>
                        {isLoading && loadingStepType === 'image' ? (
                            <CircularProgress size={24} color="inherit" sx={{ color: themeParams.button_color, }} />
                        ) : (
                            <ImageIcon sx={{ color: themeParams.button_color }} />
                        )}
                    </IconContainer>
                </Tooltip>

                {/* Icon for adding invoice */}
                <Tooltip title="Add Invoice Step">
                    <IconContainer themeParams={themeParams} onClick={() => createStep('invoice')}>
                        {isLoading && loadingStepType === 'invoice' ? (
                            <CircularProgress size={24} color="inherit" sx={{ color: themeParams.button_color, }} />
                        ) : (
                            <StarIcon sx={{ color: themeParams.button_color, }} />
                        )}
                    </IconContainer>
                </Tooltip>
            </Box>
        </Box>
    );
}

export default NewStepItem;