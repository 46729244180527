import React from 'react';
import { Box, Grid, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import FileUploadButton from './file-upload-button/FileUploadButton'; // Убедитесь, что путь правильный
import PresetSelector from './PresetSelector';
import { translate } from "../../translations";
import { styled } from "@mui/system"; // Убедитесь, что путь правильный

// Стилизованный лейбл для радиокнопок
const StyledRLabel = styled('label')(({ themeParams }) => ({
    fontSize: '12px',
    color: themeParams.section_header_text_color,
    marginBottom: '5px',
    textTransform: 'uppercase',
    cursor: 'pointer', // Добавляем курсор для интерактивности
}));

// Стилизованный Radio
const StyledRadio = styled(Radio)(({ themeParams }) => ({
    color: `${themeParams.section_header_text_color} !important`,
    '&.Mui-checked': {
        color: `${themeParams.button_color} !important`,
    },
}));

const CourseCoverSelector = ({ customCoverImage, setCustomCoverImage, preset, setPreset, file, handleFileChange, themeParams, language }) => {
    const handleLabelClick = (value) => {
        setCustomCoverImage(value === "upload");
    };

    return (
        <div>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="cover-image-mode"
                    name="cover-image-mode"
                    value={customCoverImage ? "upload" : "generate"}
                    onChange={(e) => setCustomCoverImage(e.target.value === "upload")}
                >
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormControlLabel
                                value="upload"
                                control={<StyledRadio themeParams={themeParams} />}
                                label={
                                    <StyledRLabel
                                        themeParams={themeParams}
                                        onClick={() => handleLabelClick("upload")}
                                    >
                                        {translate(language, 'upload')}
                                    </StyledRLabel>
                                }
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                value="generate"
                                control={<StyledRadio themeParams={themeParams} />}
                                label={
                                    <StyledRLabel
                                        themeParams={themeParams}
                                        onClick={() => handleLabelClick("generate")}
                                    >
                                        {translate(language, 'generate')}
                                    </StyledRLabel>
                                }
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
            </FormControl>
            {customCoverImage ? (
                <Box mb={2}>
                    <FileUploadButton
                        language={language}
                        themeParams={themeParams}
                        className={"mb-16"}
                        onFileChange={handleFileChange} // Убедитесь, что setFile обновляет состояние
                        selectedFileName={file ? file.name : null}
                        title={translate(language, 'upload_cover_image')}
                        buttonText={translate(language, 'choose_image')}
                    />
                </Box>
            ) : (
                <Box mb={2}>
                    <PresetSelector
                        label={translate(language, 'choose_preset')}
                        style={{ marginBottom: '20px' }}
                        value={preset}
                        themeParams={themeParams}
                        onChange={(e) => setPreset(e.target.value)}
                    />
                </Box>
            )}
        </div>
    );
};

export default CourseCoverSelector;