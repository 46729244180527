import React from 'react';
import {Card, Typography, Divider, IconButton} from '@mui/material';
import { styled } from '@mui/system';
import useWebApp from '../twa/useWebApp';
import CoverGenerator from "./CoverGenerator";
import {useSelector} from "react-redux";
import EditIcon from "@mui/icons-material/Edit";

// Вынесите стили вне компонента
const StyledCard = styled(Card)(({ themeParams }) => ({
    backgroundColor: themeParams?.section_bg_color || '#fff', // Значение по умолчанию
    borderRadius: '20px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    position: 'relative',
}));

const ImageWrapper = styled('div')({
    width: '100%',
    height: '200px',
    overflow: 'hidden',
});

const StyledImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const ContentWrapper = styled('div')({
    padding: '16px',
});

const TitleTypography = styled(Typography)({
    marginTop: '8px',
    fontSize: '1.1rem',
    fontWeight: '500',
    textAlign: 'left',
});

const DescriptionTypography = styled(Typography)(({ themeParams }) => ({
    marginTop: '8px',
    color: themeParams?.text_secondary_color || '#6c757d', // Значение по умолчанию
    fontSize: '0.875rem',
    textAlign: 'left',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
}));

const CourseCard = ({ handleCardClick }) => {
    let WebApp = useWebApp();
    const themeParams = WebApp.themeParams || {};
    const course = useSelector(state => state.selectedCourse);

    return (
        <StyledCard themeParams={themeParams}>
            <ImageWrapper>
                {course.image_url ? (
                    <StyledImage src={course.image_url} alt={course.title} />
                ) : (
                    course.settings?.course_cover_preset && (
                        <CoverGenerator width={300} preset={course.settings.course_cover_preset}
                                        text={course.title} />
                    )
                )}
            </ImageWrapper>
            <ContentWrapper>
                <TitleTypography>{course.title}</TitleTypography>
                <Divider sx={{ my: 1 }} />
                <DescriptionTypography themeParams={themeParams}>
                    {course.description}
                </DescriptionTypography>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        backgroundColor: `${themeParams.button_color}AA`, // Прозрачность фона
                        backgroundImage: 'none',
                        backdropFilter: 'blur(5px)', // Эффект размытия
                        '&:hover': {
                            backgroundColor: `${themeParams.button_color}CC`, // Прозрачность при наведении
                        },
                    }}
                    onClick={(event) => {
                        event.stopPropagation(); // Prevents the card click event from being triggered
                        handleCardClick();
                    }}
                    aria-label="edit"
                >
                    <EditIcon sx={{ color: themeParams.button_text_color }} />
                </IconButton>
            </ContentWrapper>
        </StyledCard>
    );
};

export default CourseCard;