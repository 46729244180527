import React from 'react';
import { styled, css } from '@mui/system';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star'; // Import an icon for editing

const Header = ({ dark, themeParams }) => {
    // Styled component for Header with static styling
    const HeaderContainer = styled(AppBar)(({ theme }) => ({
        position: 'static', // Header is positioned normally in the document flow
        backgroundColor: `${themeParams.secondary_bg_color} !important`,
        backgroundImage: 'none',
        boxShadow: 'none', // No shadow
        height: '70px', // Set a consistent height
        zIndex: 1100,
    }));

    const LogoImage = styled('img')(() => ({
        height: '30px', // Fixed height for the logo
        objectFit: 'contain',
    }));

    const StyledToolbar = styled(Toolbar)(
        css({
            display: 'flex',
            justifyContent: 'space-between', // Space between logo and tariff elements
            alignItems: 'center', // Center elements vertically
            backgroundColor: `${themeParams.secondary_bg_color} !important`,
            padding: '0 20px', // Padding around the content inside the toolbar
        })
    );

    const TariffContainer = styled('div')(
        css({
            display: 'flex',
            alignItems: 'center', // Align text and icon vertically
            gap: '3px', // Space between the tariff text and the icon
        })
    );

    const TariffText = styled(Typography)(
        css({
            fontWeight: 'bold',
            textTransform: 'uppercase', // Make tariff plan name uppercase
            fontSize: '14px', // Adjust font size as needed
            marginTop: '2px', // Adjust top margin to align with the icon
            lineHeight: 1, // Adjust line height to align with the icon
            cursor: 'pointer', // Add a pointer cursor to indicate it is clickable
        })
    );

    // Styled Star Icon with custom color
    const CustomStarIcon = styled(StarIcon)(
        css({
            color: `${themeParams.button_color} !important`, // Set custom color
        })
    );

    // Function to handle vibration on click
    const handleTariffClick = () => {
        if (navigator.vibrate) {
            navigator.vibrate(100); // Vibrate for 100 milliseconds
        }
    };

    return (
        <HeaderContainer>
            <StyledToolbar disableGutters>
                <LogoImage
                    src={
                        !dark
                            ? 'https://coob.app/assets/images/logo/logo-text.svg'
                            : 'https://coob.app/assets/images/logo/logo-text-on-dark.svg'
                    }
                    alt="Coob Logo"
                />
                <TariffContainer>
                    <TariffText
                        variant="body2"
                        color="textPrimary"
                        onClick={handleTariffClick} // Add click handler for vibration
                    >
                        10
                    </TariffText>
                    <IconButton size="small" color="primary" aria-label="Change Tariff">
                        <CustomStarIcon />
                    </IconButton>
                </TariffContainer>
            </StyledToolbar>
        </HeaderContainer>
    );
};

export default Header;