import React from 'react';
import { styled } from '@mui/system';

// Создаем компонент StyledSelectContainer и стили для Select и Label
const StyledSelectContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const StyledLabel = styled('label')(({ themeParams }) => ({
    fontSize: '12px',
    color: themeParams.section_header_text_color, // Используем цвет из themeParams
    marginBottom: '5px',
    textTransform: 'uppercase', // Делаем текст заголовка большими буквами
}));

const StyledSelect = styled('select')(({ themeParams }) => ({
    width: '100%',
    padding: '10px 15px',
    fontSize: '16px',
    color: themeParams.text_color,
    borderRadius: '12px',
    marginBottom: '10px',
    border: `1px solid ${themeParams.section_header_text_color}`,
    outline: 'none',
    backgroundColor: themeParams.secondary_bg_color,
    transition: 'border-color 0.3s',
    appearance: 'none', // Убираем стандартный вид браузерного селекта
    '&:focus': {
        border: `2px solid ${themeParams.button_color}`,
        backgroundColor: themeParams.secondary_bg_color,
    },
}));

// Компонент CustomSelect с параметрами label, value, onChange, options и themeParams
const CustomSelect = ({ ref, label, value, onChange, options, onFocus, onBlur, themeParams }) => {
    return (
        <StyledSelectContainer>
            <StyledLabel themeParams={themeParams}>{label}</StyledLabel>
            <StyledSelect
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                themeParams={themeParams} // Передаем themeParams для использования цвета
            >
                <option value="" disabled>
                    Select an option
                </option>
                {options.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </StyledSelect>
        </StyledSelectContainer>
    );
};

export default CustomSelect;