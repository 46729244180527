// store.js
import { createStore, combineReducers } from 'redux';

// Action Types
const SET_TAB = 'SET_TAB';
const SET_SELECTED_ASSIGNMENT = 'SET_SELECTED_ASSIGNMENT';
const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE';
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'; // Новый тип действия для токена
const SET_SELECTED_STEP = 'SET_SELECTED_STEP'; // Новый тип действия для шага

// Action Creators
export const setTab = (tab) => ({
    type: SET_TAB,
    payload: tab,
});

export const setSelectedAssignment = (assignment) => ({
    type: SET_SELECTED_ASSIGNMENT,
    payload: assignment,
});

export const setSelectedCourse = (course) => ({
    type: SET_SELECTED_COURSE,
    payload: course,
});

export const setAccessToken = (token) => ({
    type: SET_ACCESS_TOKEN,
    payload: token,
});

export const setSelectedStep = (step) => ({ // Новый action creator для шага
    type: SET_SELECTED_STEP,
    payload: step,
});

// Initial State
const initialState = {
    tab: 0,
    selectedAssignment: null,
    selectedCourse: null,
    accessToken: null, // Инициализируем состояние токена
    selectedStep: null, // Инициализируем состояние шага
};

// Reducers
const tabReducer = (state = initialState.tab, action) => {
    switch (action.type) {
        case SET_TAB:
            return action.payload;
        default:
            return state;
    }
};

const assignmentReducer = (state = initialState.selectedAssignment, action) => {
    switch (action.type) {
        case SET_SELECTED_ASSIGNMENT:
            return action.payload;
        default:
            return state;
    }
};

const courseReducer = (state = initialState.selectedCourse, action) => {
    switch (action.type) {
        case SET_SELECTED_COURSE:
            return action.payload;
        default:
            return state;
    }
};

const accessTokenReducer = (state = initialState.accessToken, action) => {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            return action.payload;
        default:
            return state;
    }
};

const selectedStepReducer = (state = initialState.selectedStep, action) => { // Редьюсер для шага
    switch (action.type) {
        case SET_SELECTED_STEP:
            return action.payload;
        default:
            return state;
    }
};

// Комбинируем все редьюсеры
const rootReducer = combineReducers({
    tab: tabReducer,
    selectedAssignment: assignmentReducer,
    selectedCourse: courseReducer,
    accessToken: accessTokenReducer,
    selectedStep: selectedStepReducer, // Добавляем новый редьюсер для шага
});

// Создаем хранилище
const store = createStore(rootReducer);

export default store;